var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-page"},[_c('header',{staticClass:"container pt-3 text-right"},[_c('router-link',{staticClass:"btn close_btn shadow-none",attrs:{"to":{
        name: 'NumberInfoMobile',
        params: { msisdn: _vm.info.msisdn, categoryId: this.info.category.id },
      },"tag":"button"}})],1),_c('main',{staticClass:"main-page__body-m container"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":_vm.classNumberImg,"width":"88","height":"88"}}),_c('div',{staticClass:"change_m_class_name"},[_vm._v(_vm._s(_vm.classNumberName))]),_c('div',{staticClass:"change_m_msisdn mt-4"},[_vm._v(_vm._s(_vm.formattedMsisdn))]),_c('div',{staticClass:"change_m_total_sum mt-2"},[_vm._v(" "+_vm._s(_vm.info.total_sum)+" "),_c('span',{staticClass:"currency"},[_vm._v("с")])]),_c('div',{staticClass:"change_m_combination_help_text mt-4"},[_vm._v(" "+_vm._s(_vm.$t("info.platina_class"))+" ")])]),_c('div',{staticClass:"cpo_info_wrapper d-flex flex-row align-items-end"},[_vm._m(0),_c('div',[_c('a',{attrs:{"target":"_blank","href":_vm.$t('info.cpo_addr_link')}},[_vm._v(_vm._s(_vm.$t("info.cpo_addr")))])])]),_c('div',[_c('router-link',{staticClass:"btn btn-accept-filter btn-block",attrs:{"to":{
          name: 'NumberInfoMobile',
          params: { msisdn: _vm.info.msisdn, categoryId: this.info.category.id },
        },"tag":"button"}},[_vm._v("Ок")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/icons/cpo.png"),"width":"32","height":"32"}})])
}]

export { render, staticRenderFns }