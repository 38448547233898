<template>
  <div class="main-page">
    <header class="container pt-3 text-right">
      <router-link
        :to="{
          name: 'NumberInfoMobile',
          params: { msisdn: info.msisdn, categoryId: this.info.category.id },
        }"
        tag="button"
        class="btn close_btn shadow-none"
      ></router-link>
    </header>
    <main class="main-page__body-m container">
      <div class="text-center">
        <img :src="classNumberImg" width="88" height="88" />
        <div class="change_m_class_name">{{ classNumberName }}</div>
        <div class="change_m_msisdn mt-4">{{ formattedMsisdn }}</div>
        <div class="change_m_total_sum mt-2">
          {{ info.total_sum }} <span class="currency">с</span>
        </div>
        <div class="change_m_combination_help_text mt-4">
          {{ $t("info.platina_class") }}
        </div>
      </div>
      <div class="cpo_info_wrapper d-flex flex-row align-items-end">
        <div><img src="@/assets/icons/cpo.png" width="32" height="32" /></div>
        <div>
          <a target="_blank" :href="$t('info.cpo_addr_link')">{{
            $t("info.cpo_addr")
          }}</a>
        </div>
      </div>
      <div>
        <router-link
          :to="{
            name: 'NumberInfoMobile',
            params: { msisdn: info.msisdn, categoryId: this.info.category.id },
          }"
          tag="button"
          class="btn btn-accept-filter btn-block"
          >Ок</router-link
        >
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "RestricredActionsMobile",
  props: {
    info: Object,
  },
  computed: {
    classNumberName() {
      return this.$t(this.info.category.label_c);
    },
    classNumberImg() {
      return require(`@/assets/icons/${this.info.category.img_alt}`);
    },
    formattedMsisdn() {
      return this.info.msisdn
        .replace("996", "0")
        .replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    },
  },
};
</script>

